<template>
    <div :id="cmsBlock.anchorTag" class="menu-tiles">
        <div v-for="menuTile in cmsBlock.menuTiles" class="menu-tile">
            <img
                v-if="menuTile.backgroundImage"
                class="background" 
                :src="getImageUrl(menuTile.backgroundImage.data.attributes.url)"
                :alt="menuTile.backgroundImage.data.attributes.alternativeText"
            />
            <div class="content" :class="{'dark': !menuTile.backgroundImage}">
                <a
                    v-if="menuTile.titleLinkType"
                    :href="url(menuTile, menuTile.titleLinkType)"
                    :target="target(menuTile, menuTile.titleLinkType)"
                >
                    <h3 class="title">{{ localize(menuTile.title) }}</h3>
                </a>
                <h3 v-else class="title">{{ localize(menuTile.title) }}</h3>
                <div class="menu-items">
                    <a
                        class="menu-item"
                        v-for="menuItem in menuTile.menuItems"
                        :href="url(menuItem)"
                        :target="target(menuItem)"
                    >
                        {{ localize(menuItem.title) }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TiledMenu',

        props: [
            'cmsBlock'
        ],

        methods: {
            url(menuItem, linkType = null) {
                if(!linkType) {
                    linkType = menuItem.linkType
                }
                switch(linkType){
                    case 'internal':
                        return menuItem.internalUrl
                    case 'external': 
                        return this.localize(menuItem.externalUrl)
                    case 'anchor':
                        return menuItem.anchor
                }
            },

            target(menuItem, linkType = null) {
                if(!linkType) {
                    linkType = menuItem.linkType
                }
                switch(linkType){
                    case 'external':
                        return '_blank'
                    default:
                        return '_self'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .menu-tiles {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @include breakpoint('mobile') {
            grid-template-columns: 1fr;
        }

        .menu-tile {
            height: 458px;
            position: relative;
            overflow: hidden;
            background: $lightGrey;

            @include breakpoint('mobile') {
                height: 300px;
                padding-top: 50px;
            }

            .background {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .content {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                padding-top: 80px;

                @include breakpoint('mobile') {
                    padding-top: 55px;
                }

                &.dark {
                    color: $timberGreen;

                    a {
                        color: $timberGreen;
                    }
                }

                .title {
                    width: 100%;
                    text-align: center;
                    hyphens: auto;
                    color: inherit;
                    font-size: getFontSize('textHeader', 'desktop');

                    @include breakpoint('tablet') {
                        font-size: getFontSize('textHeader', 'tablet');
                    }
                    @include breakpoint('mobile') {
                        font-size: getFontSize('textHeader', 'mobile');
                        margin-bottom: 4px;
                    }
                }

                .menu-items {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;
                    justify-content: center;

                    .menu-item {
                        line-height: 40px;
                        color: inherit;
                        font-weight: bold;
                        font-size: getFontSize('smallText', 'desktop');

                        @include breakpoint('tablet') {
                            font-size: getFontSize('smallText', 'tablet');
                        }
                        @include breakpoint('mobile') {
                            font-size: getFontSize('smallText', 'mobile');
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }
</style>
